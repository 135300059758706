import React from "react";
import "./Home.scss";
import MyWork from "../MyWork/MyWork";
import SkillBreakdown from "../SkillBreakdown/SkillBreakdown";
import Cta from "../Cta/Cta";

const Home = () => {
  return (
    <div className="Home">
      <Cta fullCta githubLink heading={"Principal Web Developer"} subHeading={"Gen (NortonLifeLock)"} lead={"I love to code/market great products, apps, websites and more."} />
      <section className="container-fluid Home-intro">
        <div className="whiteSection">
          <div className="row justify-content-md-center">
            <div className="col-md-6 p-5 Home-intro-text">
              <h2 className="mb-4">Experience</h2>
              <p>
                I'm Joshua O'Brien and I have been working in web development from both a Front-End and
                Back-End perspective for more then a decade and I
                pride myself on making easy-to-use and beautiful applications,
                products and websites. Based in Portlaoise in Ireland.
              </p>
            </div>
          </div>
          <SkillBreakdown />
        </div>
        <MyWork />
      </section>
    </div>
  );
}

export default Home;
