import React from "react";
import { NavLink } from "react-router-dom";
import "./Header.scss";

const Header = () => {
  return (
    <div className="Header d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3">
      <nav className="my-2 my-md-0 mr-md-3 flex">
        <NavLink
          className={({ isActive }) =>
            isActive ? "active justify-start p-2" : "inactive justify-start p-2"
          }
          to="/"
        >
          Home
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "active justify-start p-2" : "inactive justify-start p-2"
          }
          to="/about"
        >
          About
        </NavLink>
        {/* <NavLink className={({ isActive }) => (isActive ? 'active justify-start p-2' : 'inactive justify-start p-2')} to="/blog">Blog</NavLink>   */}
        <a
          className="p-2 justify-end"
          href="https://marketplace.atlassian.com/apps/1232571/scrum-poker-estimates-for-jira?hosting=cloud&tab=overview"
        >
          Scrum Poker App - Jira Integration
        </a>
      </nav>
    </div>
  );
};

export default Header;
