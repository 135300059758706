import React from 'react';

const SkillBreakdown = () => {
    return (
        <div className="row skillBreakdown">
        <div className="box col-10 offset-1 row mb-4">
          <div className="col-md-4 col-sm-12 bordBox">
            <img alt="" src="" />
            <h3>Developer</h3>
            <p>
              I love writing readable, maintainable code that really brings
              a product to life.
            </p>
            <strong>
              <label>Languages and Libraries I Use:</label>
            </strong>
            <p>
              PHP, JavaScript (React/Ember/Angular/Vue<br />/Handlebars), LESS <br />
              SASS, JQuery, SQL, .Net, HTML, CSS
            </p>
            <strong>
              <label>Dev Tools and Frameworks</label>
            </strong>
            <ul className="skills">
              <li>Atom</li>
              <li>Angular</li>
              <li>Bitbucket</li>
              <li>Bootstrap</li>
              <li>Codepen</li>
              <li>Github</li>
              <li>Gulp</li>
              <li>Gitbash</li>
              <li>Handlebars</li>
              <li>Homestead</li>
              <li>Ionic</li>
              <li>JSFiddle</li>
              <li>Laravel 5.7 and earlier</li>
              <li>NPM</li>
              <li>PHP Storm</li>
              <li>React</li>
              <li>Terminal</li>
              <li>Vagrant</li>
              <li>Visual Studio</li>
            </ul>
          </div>
          <div className="col-md-4 col-sm-12 bordBox">
            <img alt="" src="" />
            <h3>Designer</h3>
            <p>
              I enjoy creating and tailoring stylish designs in a number of
              different areas.
            </p>
            <strong>
              <label>Stuff I enjoy Designing/Creating</label>
            </strong>
            <p>
              UI, UX, Web, Mobile, Apps,
              <br /> Logos, Art.
            </p>
            <strong>
              <label>Design Tools</label>
            </strong>
            <ul className="skills">
              <li>Balsamiq</li>
              <li>Illustrator</li>
              <li>Ink Email Boilerplate</li>
              <li>Inkscape</li>
              <li>Litmus</li>
              <li>Marvel</li>
              <li>Mailchimp</li>
              <li>Pen/Paper/Wacom</li>
              <li>Photoshop</li>
              <li>Webflow</li>
              <li>Zeplin</li>
            </ul>
          </div>
          <div className="col-md-4 col-sm-12 finalBordBox">
            <img alt="" src="" />
            <h3>Marketer</h3>
            <p>
              I love marketing and promoting a new product through seo and
              other digital marketing fronts.
            </p>
            <strong>
              <label>Products I love to Market</label>
            </strong>
            <p>Apps, SAAS, Websites, Services, Tangible-Products</p>
            <strong>
              <label>Marketing Tools</label>
            </strong>
            <ul className="skills">
              <li>Adwords</li>
              <li>Adsense</li>
              <li>Ahrefs</li>
              <li>Direct Communication</li>
              <li>Facebook</li>
              <li>Google Webmaster Tools</li>
              <li>Google Plus</li>
              <li>Hootsuite</li>
              <li>LinkedIn</li>
              <li>Mailchimp</li>
              <li>Metas</li>
              <li>Moz</li>
              <li>Product Hunt</li>
              <li>Screaming Frog</li>
              <li>Twitter</li>
              <li>TweetDeck</li>
            </ul>
          </div>
        </div>
      </div>
     );
}

export default SkillBreakdown;