const GoodReads = () => {
    return (
        <div id="gr_custom_widget_1647803031">
   <div className="gr_custom_container_1647803031">
      <h2 className="gr_custom_header_1647803031">
         <a style={{textDecoration: "none"}} rel="nofollow" href="https://www.goodreads.com/review/list/95526060-josh-o-brien?shelf=currently-reading&amp;utm_medium=api&amp;utm_source=custom_widget">Josh O&#39;Brien&#39;s Current Reads</a>
      </h2>
      <div className="gr_custom_each_container_1647803031">
         <div className="gr_custom_book_container_1647803031">
            <a title="Tokyo Vice: An American Reporter on the Police Beat in Japan" rel="nofollow" href="https://www.goodreads.com/review/show/4615534909?utm_medium=api&amp;utm_source=custom_widget"><img alt="Tokyo Vice: An American Reporter on the Police Beat in Japan" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1320453731l/6658129._SY75_.jpg" /></a>
         </div>
         <div className="gr_custom_rating_1647803031">
            <span className=" staticStars notranslate"><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /></span>
         </div>
         <div className="gr_custom_title_1647803031">
            <a rel="nofollow" href="https://www.goodreads.com/review/show/4615534909?utm_medium=api&amp;utm_source=custom_widget">Tokyo Vice: An American Reporter on the Police Beat in Japan</a>
         </div>
         <div className="gr_custom_author_1647803031">
            by <a rel="nofollow" href="https://www.goodreads.com/author/show/3001096.Jake_Adelstein">Jake Adelstein</a>
         </div>
         <div className="gr_custom_tags_1647803031">
            tagged:
            currently-reading
         </div>
      </div>
      <div className="gr_custom_each_container_1647803031">
         <div className="gr_custom_book_container_1647803031">
            <a title="The Priory of the Orange Tree" rel="nofollow" href="https://www.goodreads.com/review/show/4338023549?utm_medium=api&amp;utm_source=custom_widget"><img alt="The Priory of the Orange Tree" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1527807139l/40275288._SY75_.jpg" /></a>
         </div>
         <div className="gr_custom_rating_1647803031">
            <span className=" staticStars notranslate"><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /></span>
         </div>
         <div className="gr_custom_title_1647803031">
            <a rel="nofollow" href="https://www.goodreads.com/review/show/4338023549?utm_medium=api&amp;utm_source=custom_widget">The Priory of the Orange Tree</a>
         </div>
         <div className="gr_custom_author_1647803031">
            by <a rel="nofollow" href="https://www.goodreads.com/author/show/5830526.Samantha_Shannon">Samantha Shannon</a>
         </div>
         <div className="gr_custom_tags_1647803031">
            tagged:
            currently-reading
         </div>
      </div>
      <div className="gr_custom_each_container_1647803031">
         <div className="gr_custom_book_container_1647803031">
            <a title="The Indifferent Stars Above: The Harrowing Saga of a Donner Party Bride" rel="nofollow" href="https://www.goodreads.com/review/show/4204312800?utm_medium=api&amp;utm_source=custom_widget"><img alt="The Indifferent Stars Above: The Harrowing Saga of a Donner Party Bride" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1438825353l/6033525._SY75_.jpg" /></a>
         </div>
         <div className="gr_custom_rating_1647803031">
            <span className=" staticStars notranslate"><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /></span>
         </div>
         <div className="gr_custom_title_1647803031">
            <a rel="nofollow" href="https://www.goodreads.com/review/show/4204312800?utm_medium=api&amp;utm_source=custom_widget">The Indifferent Stars Above: The Harrowing Saga of a Donner Party Bride</a>
         </div>
         <div className="gr_custom_author_1647803031">
            by <a rel="nofollow" href="https://www.goodreads.com/author/show/486329.Daniel_James_Brown">Daniel James Brown</a>
         </div>
         <div className="gr_custom_tags_1647803031">
            tagged:
            currently-reading
         </div>
      </div>
      <div className="gr_custom_each_container_1647803031">
         <div className="gr_custom_book_container_1647803031">
            <a title="A People's History of the United States" rel="nofollow" href="https://www.goodreads.com/review/show/3927723652?utm_medium=api&amp;utm_source=custom_widget"><img alt="A People's History of the United States" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1494279423l/2767._SY75_.jpg" /></a>
         </div>
         <div className="gr_custom_rating_1647803031">
            <span className=" staticStars notranslate"><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /></span>
         </div>
         <div className="gr_custom_title_1647803031">
            <a rel="nofollow" href="https://www.goodreads.com/review/show/3927723652?utm_medium=api&amp;utm_source=custom_widget">A People's History of the United States</a>
         </div>
         <div className="gr_custom_author_1647803031">
            by <a rel="nofollow" href="https://www.goodreads.com/author/show/1899.Howard_Zinn">Howard Zinn</a>
         </div>
         <div className="gr_custom_tags_1647803031">
            tagged:
            currently-reading
         </div>
      </div>
      <div className="gr_custom_each_container_1647803031">
         <div className="gr_custom_book_container_1647803031">
            <a title="Starship Troopers" rel="nofollow" href="https://www.goodreads.com/review/show/3926271155?utm_medium=api&amp;utm_source=custom_widget"><img alt="Starship Troopers" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1614054412l/17214._SY75_.jpg" /></a>
         </div>
         <div className="gr_custom_rating_1647803031">
            <span className=" staticStars notranslate"><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /><img alt="" src="https://s.gr-assets.com/images/layout/gr_red_star_inactive.png" /></span>
         </div>
         <div className="gr_custom_title_1647803031">
            <a rel="nofollow" href="https://www.goodreads.com/review/show/3926271155?utm_medium=api&amp;utm_source=custom_widget">Starship Troopers</a>
         </div>
         <div className="gr_custom_author_1647803031">
            by <a rel="nofollow" href="https://www.goodreads.com/author/show/205.Robert_A_Heinlein">Robert A. Heinlein</a>
         </div>
         <div className="gr_custom_tags_1647803031">
            tagged:
            currently-reading
         </div>
      </div>
      <br style={{clear: "both"}}/>
      <center>
         <a rel="nofollow" href="https://www.goodreads.com/"><img alt="goodreads.com" style={{border:0}} src="https://s.gr-assets.com/images/widget/widget_logo.gif" /></a>
      </center>
      <noscript>
         Share <a rel="nofollow" href="https://www.goodreads.com/">book reviews</a> and ratings with Josh O'Brien, and even join a <a rel="nofollow" href="https://www.goodreads.com/group">book club</a> on Goodreads.
      </noscript>
   </div>
</div>
    )
}

export default GoodReads;