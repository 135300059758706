import React from 'react';

const MyWork = () => {
    return (         <div className="row justify-content-md-center Home-work">
    <div className="col-md-6 p-5">
      <h2>My Work</h2>
      <p>
        Here are just a handful of the sites I've developed or worked on
        over the course of my career, for more information please feel
        free to contact me.
      </p>
    </div>
    <div className="row col-12 justify-content-center">
      <div className="col-md-3 m-2 servBox">
        <a title="Sothebys Catalogue Site" aria-label="Sothebys Catalogue Site" href="https://www.sothebys.com/en/digital-catalogues/19th-century-european-art-june?locale=en">
          <img
            alt="Sothebys Catalogue Site"
            className="img2"
            src="/img/sothebys.png"
          />
        </a>
      </div>
      <div className="col-md-3 m-2 servBox">
        <a title="Ocean Finance" aria-label="Ocean Finance"  href="https://www.oceanfinance.co.uk/">
          <img alt="Ocean Finance Websie" src="/img/ocean.png" />
        </a>
      </div>
      <div className="col-md-3 m-2 servBox">
        <a title="Amazon About Site" aria-label="Amazon About Site"  href="https://www.aboutamazon.com/">
          <img
            alt="Amazon Blogs/Day One/Music/About/Packages"
            src="/img/amazon-logo.png"
          />
        </a>
      </div>
    </div>
    <div className="row col-12 justify-content-center">
      <div className="col-md-3 m-2 servBox">
        <a title="ThinkMoney Site" aria-label="ThinkMoney Site"  href="https://www.thinkmoney.co.uk/">
          <img alt="Thinkmoney Websie" src="/img/think.jpg" />
        </a>
      </div>
      <div className="col-md-3 m-2 servBox">
      <a title="Phantom Site" aria-label="Phantom Site"  href="https://phantom.uk.net/">
        <img
          alt="Phantom Tracking Website and MyPhantom Platform"
          src="/img/logoPhantom.png"
        />
        </a>
      </div>
      <div className="col-md-3 m-2 servBox">
        <img
          alt="Toyota Ireland Sites as well as Lexus"
          className="img2"
          src="/img/toyota.png"
        />
      </div>
    </div>
    <div className="row col-12 justify-content-center">
      <div className="col-md-3 m-2 servBox laoctava">
        <img alt="La Octava Website" src="/img/laoctava.png" />
      </div>
      <div className="col-md-3 m-2 servBox">
        <img alt="El Heraldo Website" src="/img/elheraldo.png" />
      </div>
      <div className="col-md-3 m-2 servBox">
        <img
          alt="USWDS US Gov Design Recommendation Template"
          className="img2"
          src="/img/uswds.png"
        />
      </div>
    </div>
    <div className="row col-12 justify-content-center">
      <div className="col-md-3 m-2 servBox">
        <img alt="Vodafone NZ Website" src="/img/vodafone.png" />
      </div>
      <div className="col-md-3 m-2 servBox">
        <img alt="J&J Website" src="/img/j&j.png" />
      </div>
      <div className="col-md-3 m-2 servBox">
        <img
          alt="Church of Latter Day Saints"
          className="img2"
          src="/img/lds.svg"
        />
      </div>
    </div>
    <div className="row col-12 justify-content-center mb-5">
      <div className="col-md-3 m-2 servBox">
        <img alt="NPR - National Public Radio" src="/img/Npr-logo.png" />
      </div>
      <div className="col-md-3 m-2 servBox">
        <img alt="RapidRatings" src="/img/rrLogo.png" />
      </div>
      <div className="col-md-3 m-2 servBox">
        <img
          alt="Special Olympics"
          className="img2"
          src="/img/Special_Olympics_logo.png"
        />
      </div>
    </div>
  </div> );
}

export default MyWork;