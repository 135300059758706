import React from 'react';
import Typical from "react-typical";

const Cta = ({fullCta = false, githubLink = false, title="Joshua O'Brien", heading , subHeading, lead}) => {
    return (
        <section className="container-fluid Home-cta">
        <>
          {fullCta && (
              <img className="circleProfile" alt="Circle Profile" src="/img/profile.png" />
          )}
          <h1 className="cover-heading">
            <Typical
              steps={[title, 1200]}
              loop={Infinity}
              wrapper="p"
            />
          </h1>
        </>
        {heading && (
          <h2 className="cover-heading">{heading}</h2>
        )}
        {subHeading && (
        <h3 className="cover-heading">{subHeading}</h3>
        )}
        {lead && (
        <p className="lead">
          {lead}
        </p>
        )}
        {githubLink && (
          <div className="mb-2">
            <a
              title="GithubAccount for Jobrien874"
              aria-label="Github Account JOBRIEN874"
              href="https://github.com/jobrien874"
            >
              Check out my GitHub Account
            </a>
          </div>
        )}
        {fullCta && (
          <div className="row">
            <div className="col-md-12">
              <img id="homeWeb" alt="logo" src="/img/web.png" />
            </div>
          </div>
        )}
      </section>
     );
}

export default Cta;