import React from 'react';
import Cta from "../Cta/Cta";
import Goodreads from "../Goodreads/Goodreads";
import Spotify from "../Spotify/Spotify";
import { Helmet } from "react-helmet";
import "./About.scss";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Me - Joshua O'Brien Software Engineer Portlaoise in Ireland</title>
        <meta name="description" content="Joshua O'Brien Principal Web Developer at Gen (NortonLifeLock) - Javascript Developer in Portlaoise Ireland" />
      </Helmet>
    <div className="About">
        <div className="imageContainer mx-auto">
          <div className='circleOverlay'/>
          <img alt="Josh O'Brien Profile" className="circleProfileAbout" src="/img/me.jpg" style={{borderRadius:'100%'}} />
        </div>
        <Cta title="About Me" heading="Current Role:" subHeading={"Principal Web Developer at Gen (NortonLifeLock)"} lead={"Heres a Quick Synopsis About Me"}/>
        <section className="container-fluid Home-intro">
        <div className="whiteSection">
          <div className="row justify-content-md-center">
            <div className="col-md-6 p-5 Home-intro-text">
              <h2 className="mb-4">What I Do?</h2>
              <p>
                I've now been working in the Web Development and Software Development Industry for nearly 10 years. In that time
                I've grown my skill set, while currently focusing on Javascript and React. I have worked and feel comfortable in a range of technologies
                including PHP and C#, as well as a range of other Javascript frameworks including Angular and Ember.
              </p>
              <p>I'm now situated in Ireland permanently - previously I've lived North and South of England. I love a good gig, I'm an avid reader and gamer. 
                Linking my GoodReads below and Spotify so you can see what I'm currently delving through.</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center Home-work">
        <div className='row col-12 justify-content-center'>
        <div className='mx-2 my-5'>
        <Spotify />
        </div>
        <div className='mx-2 my-5'>
        <Goodreads />
        </div>
        </div>
        </div>
      </section>
    </div>
    </>
  );
}

export default About;
