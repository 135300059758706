import React from "react";
import Home from "./Components/Home/Home.jsx";
import About from "./Components/About/About.jsx";
import Header from "./Components/Header/Header.jsx"
import Footer from "./Components/Footer/Footer.jsx"
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";

function App() {
  return (
    <>
    <Helmet>
      <title>Josh OBrien - Lead UI Developer Portfolio Homepage</title>
      <meta name="description" content="Irish Software Engineer and Lead UI Developer Joshua O'Brien - 10 Years + Experience" />
    </Helmet>
    <Router>
      <Header />
      <Routes>
        <Route path="about" element={<About />} />
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
    </>
  );
}

export default App;
