import React from 'react';

const Footer = () => {


  return (
    <div className="row whiteSection p-4 Home-footer">
      <h3>Contact Me</h3>
      <p>I'm always open for a chat, any projects or ideas let me know.</p>
      <form
        className="mb-4"
        name="contact"
        method="post"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="form-group">
          <label>
            Your Name:{" "}
            <input
              placeholder="Name"
              className="form-control"
              type="text"
              name="name"
              required
            />
          </label>
          <label>
            Your Email:{" "}
            <input
              placeholder="Email"
              className="form-control"
              type="email"
              name="email"
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            Message:{" "}
            <textarea
              placeholder="Message"
              className="form-control"
              name="message"
              required
            ></textarea>
          </label>
        </div>
        <button className="btn btn-primary j-btn" type="submit">
          Send
        </button>
      </form>
      <a title="Email Address" aria-label="Email Address" href="mailto:jobrien874ie@gmail.com">
        <strong>jobrien874ie@gmail.com</strong>
      </a>
      <p className="mt-3">or hit me up on <a title="LinkedIn Account" aria-label="LinkedIn Account" href="https://www.linkedin.com/in/joshuajobrien"><strong>LinkedIn</strong></a></p>
    </div>
  );
}

export default Footer;